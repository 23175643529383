import React from "react";

import { Icon, IconDefinition } from "../Icon/Icon";
import Button from "../Button/Button";

import "./MessageTemplate.scss";

interface EmailVerificationPropsType {
  icon?: IconDefinition;
  title?: string;
  paragraph?: string;
  buttonLabel?: string;
  onButtonClick: () => void;
}

const MessageTemplate = (props: EmailVerificationPropsType) => {
  const { icon, title, paragraph, buttonLabel, onButtonClick } = props;
  return (
    <div className="MessageTemplate">
      {icon && (
        <Icon
          className="MessageTemplate__icon"
          onClick={() => {}}
          icon={icon}
        />
      )}
      {title && <p className="MessageTemplate__title">{title}</p>}
      {paragraph && <p className="MessageTemplate__paragraph">{paragraph}</p>}
      {buttonLabel && (
        <Button
          type="submit"
          style={{ width: "100%" }}
          onClick={onButtonClick}
          label={buttonLabel}
          className="Button__light"
        />
      )}
    </div>
  );
};

export default MessageTemplate;
