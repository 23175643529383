import React, { useEffect, useRef, useState } from "react";

import { useGlobalContext } from "../../context/GlobalContextProvider/GlobalContext";
import { applyActionCode } from "firebase/auth";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import { auth } from "../../configFirebase";
import useGetParameterByName from "../../lib/hooks/useGetParameterByName";
import LoadingBar from "../../components/LoadingBar/LoadingBar";
import FormCard from "../../components/FormCard/FormCard";
import MessageTemplate from "../../components/MessageTemplate/MessageTemplate";
import NewPasswordForm from "../../components/NewPasswordForm/NewPasswordForm";

import "./Email.scss";

const Email = () => {
  const [verifyEmailStatus, setVerifyEmailStatus] = useState("");
  const [resetPasswordStatus, setResetPasswordStatus] = useState("");
  const [email, setEmail] = useState("");
  const mode = useGetParameterByName("mode");
  const actionCode = useGetParameterByName("oobCode");

  const showLoader = useGlobalContext().showLoader;
  const hideLoader = useGlobalContext().hideLoader;

  useEffect(() => {
    showLoader();
    if (mode === "verifyEmail" || mode === "resetPassword") {
      if (mode === "verifyEmail") {
        applyActionCode(auth, actionCode)
          .then((response) => {
            setVerifyEmailStatus("success");
            hideLoader();
          })
          .catch((error) => {
            if (error.code === "auth/expired-action-code") {
              setVerifyEmailStatus("expired");
              hideLoader();
            } else {
              setVerifyEmailStatus("failed");
              hideLoader();
            }
          });
      }
      if (mode === "resetPassword") {
        verifyPasswordResetCode(auth, actionCode)
          .then((email) => {
            setEmail(email);
            setResetPasswordStatus("new-password");
            hideLoader();
          })
          .catch((error) => {
            if (error.code === "auth/expired-action-code") {
              setResetPasswordStatus("expired");
              hideLoader();
            } else {
              setResetPasswordStatus("failed");
              hideLoader();
            }
          });
      }
    } else {
      hideLoader();
      window.location.href = "https://www.bouncer.global/";
    }
  }, []);

  const handleResetPassword = (confirmPassword: any) => {
    confirmPasswordReset(auth, actionCode, confirmPassword)
      .then((response) => {
        setResetPasswordStatus("success");
        hideLoader();
      })
      .catch((error) => {
        if (error.code === "auth/expired-action-code") {
          setResetPasswordStatus("expired");
          hideLoader();
        } else {
          setResetPasswordStatus("failed");
          hideLoader();
        }
      });
  };

  return (
    <div className="Email">
      <LoadingBar />
      <div className="Email__container">
        <>
          {verifyEmailStatus === "success" && (
            <FormCard label="Email Verification">
              <MessageTemplate
                onButtonClick={() => {}}
                icon="email-verified"
                title="Your email has been verified"
                paragraph="You can sign in now with your new account using your credentials."
              />
            </FormCard>
          )}

          {verifyEmailStatus === "failed" && (
            <FormCard label="Email Verification">
              <MessageTemplate
                onButtonClick={() => {}}
                icon="something-wrong"
                title="Oops! Something went wrong"
                paragraph="Please try refreshing your page or try again later."
              />
            </FormCard>
          )}

          {verifyEmailStatus === "expired" && (
            <FormCard label="Email Verification">
              <MessageTemplate
                onButtonClick={() => {}}
                icon="expired-link"
                title="The link has expired"
                paragraph="The link has already been used or expired. Please request a new one."
              />
            </FormCard>
          )}
        </>
        <>
          {resetPasswordStatus === "new-password" && (
            <FormCard label="Reset Password">
              <NewPasswordForm
                email={email}
                onFormSubmit={(confirmPassword: any) => {
                  showLoader();
                  handleResetPassword(confirmPassword);
                }}
              />
            </FormCard>
          )}

          {resetPasswordStatus === "success" && (
            <FormCard label="Reset Password">
              <MessageTemplate
                onButtonClick={() => {}}
                icon="lock"
                title="Your password has been updated"
                paragraph="You can sign in to your account with your new password."
              />
            </FormCard>
          )}

          {resetPasswordStatus === "failed" && (
            <FormCard label="Reset Password">
              <MessageTemplate
                onButtonClick={() => {}}
                icon="something-wrong"
                title="Oops! Something went wrong"
                paragraph="Please try refreshing your page or try again later."
              />
            </FormCard>
          )}

          {resetPasswordStatus === "expired" && (
            <FormCard label="Reset Password">
              <MessageTemplate
                onButtonClick={() => {}}
                icon="expired-link"
                title="The link has expired"
                paragraph="The link has already been used or expired. Please request a new one."
              />
            </FormCard>
          )}
        </>
      </div>
    </div>
  );
};

export default Email;
