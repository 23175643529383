import React, { useState } from "react";

import { GlobalContext, GlobalContextValuesType } from "./GlobalContext";

interface GlobalContextProviderProps {
  children: React.ReactNode | null;
}

export const GlobalContextProvider = (props: GlobalContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const showLoader = () => {
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
  };

  const context: GlobalContextValuesType = {
    isLoading,
    showLoader,
    hideLoader,
  };

  return (
    <GlobalContext.Provider value={context}>
      {props.children}
    </GlobalContext.Provider>
  );
};
