import React, { useState } from "react";

import { Icon } from "../Icon/Icon";

import cs from "classnames";

import "./InputField.scss";

interface InputFieldProps {
  name?: string;
  icon?: boolean;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  value?: string | number;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  className?: string;
  style?: React.CSSProperties;
  minimalNumber?: string;
  step?: any;
  errorText?: any;
}

const InputField = (props: InputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    name,
    icon,
    placeholder,
    type,
    disabled,
    value,
    onBlur,
    onChange,
    style,
    minimalNumber,
    step,
    errorText,
    className,
  } = props;

  const onIconClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={cs("InputField", className)}>
      <div
        className={cs(
          "InputField__container",
          disabled && `InputField__container--disabled`,
          errorText && `InputField__container--error`
        )}
      >
        <input
          min={minimalNumber}
          step={step}
          name={name}
          style={style}
          placeholder={placeholder}
          className={cs(
            "InputField__input",
            disabled && `InputField__input--disabled`
          )}
          type={icon ? (showPassword ? "text" : "password") : type}
          disabled={disabled}
          value={value === null ? String(value) : value}
          onBlur={onBlur}
          onChange={onChange}
        />
        {icon && (
          <Icon
            className="InputField__icon"
            onClick={onIconClick}
            icon={showPassword ? "hidden-password" : "visible-password"}
          />
        )}
      </div>
      {errorText && (
        <p className="InputField__container--errorMessage">{errorText} </p>
      )}
    </div>
  );
};

export default InputField;
