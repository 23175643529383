import React, { useEffect } from "react";
import useGetParameterByName from "../../lib/hooks/useGetParameterByName";

const Home = () => {
  const linkSearchParam = useGetParameterByName("link");

  useEffect(() => {
    if (linkSearchParam !== "") {
      window.location.href = linkSearchParam.replace("email/", "email");
    } else {
      window.location.href = "https://www.bouncer.global/";
    }
  }, []);

  return <div className="Home"></div>;
};

export default Home;
