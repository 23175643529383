import { createContext, useContext } from "react";

export interface GlobalContextValuesType {
  isLoading: boolean;
  showLoader: () => void;
  hideLoader: () => void;
}

const GlobalContextValues: GlobalContextValuesType = {
  isLoading: false,
  showLoader: () => {},
  hideLoader: () => {},
};

export const GlobalContext = createContext(GlobalContextValues);

export const useGlobalContext = () => useContext(GlobalContext);
